import Axios, { AxiosInstance } from "axios";
import config from "../config/config";

const axiosInstance: AxiosInstance = Axios.create({
    baseURL: `${config.apiUrl}`,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
});

export function authHeader(contentType: string = 'application/json') {
    const token = localStorage.getItem('token');
    if (token !== null) {
        return {
            Authorization: 'Bearer ' + token,
            'Content-Type': contentType,
        };
    } else {
        return {
            'Content-Type': contentType,
        };
    }
}

export default axiosInstance;
