import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { useGeneratedExtractions } from "../../contexts/extractions/GeneratedExtractionsContext";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import GeneratedExtractionModel from "../../contexts/extractions/GeneratedExtractionModel";
import DeleteButton from "../../components/buttons/DeleteButton";
import { useAccess } from "../../contexts/access/AccessContext";
import { PaginatorPageChangeEvent } from "primereact/paginator";
import DataTablePaginator from "../../components/DataTablePaginator";

const ExtractionsTable: React.FC = () => {
    const { t } = useTranslation();
    const { canDeleteExtraction } = useAccess();

    const { loadExtractions, extractions, numFound, deleteExtraction } =
        useGeneratedExtractions();

    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(25);

    const documentsButton = (extraction: GeneratedExtractionModel) => {
        return (
            <Link to={`/documents?extractionId=${extraction.id}`}>
                <Button outlined label={t("Show in documents")} />
            </Link>
        );
    };

    const categoriesButton = (extraction: GeneratedExtractionModel) => {
        return (
            <Link to={`/categories?extractionId=${extraction.id}`}>
                <Button outlined label={t("Show in categories")} />
            </Link>
        );
    };

    const handlePageChange = (e: PaginatorPageChangeEvent) => {
        setOffset(e.first);
        setLimit(e.rows);
        loadExtractions(e.first, e.rows);
    };

    return (
        <div>
            <DataTable
                value={extractions}
                scrollable
                scrollHeight="calc(100vh - 210px)"
            >
                <Column field="id" header={t("id")} />
                <Column field="generated_by" header={t("Generated by")} />
                <Column field="generated_at" header={t("Generated at")} />
                <Column field="target_pages" header={t("Target pages count")} />
                <Column
                    field="selected_pages"
                    header={t("Selected pages count")}
                />
                <Column field="documents_count" header={t("Documents count")} />
                <Column body={documentsButton} />
                <Column body={categoriesButton} />
                <Column
                    body={(extraction) => (
                        <DeleteButton
                            itemId={extraction.id}
                            disabled={!canDeleteExtraction(extraction)}
                            onDeleteItem={deleteExtraction}
                        />
                    )}
                />
            </DataTable>
            <DataTablePaginator
                offset={offset}
                limit={limit}
                numFound={numFound}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default ExtractionsTable;
